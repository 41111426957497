import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { getPopularProduct } from 'services/product.service';

export const QueryPopularProductsKey = 'getPopularProducts';

const usePopularProducts = (options: Record<string, any>, currency?: string) => (
  useQuery(`${QueryPopularProductsKey}${currency}`, () => getPopularProduct(currency)
    .then((result: AxiosResponse<Product.Item[]>) => result.data), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
  }));

export default usePopularProducts;
