import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { getCategories } from 'services/category.service';

export const QueryKey = 'getCategories';

const useCategories = (options?: Record<string, any>) => useQuery(
  QueryKey,
  () => getCategories()
    .then((result: AxiosResponse<Category[]>) => result.data),
  {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  },
);

export default useCategories;
